<template>
  <div class="flex flex-col w-full">
    <div class="flex justify-center">
      <div class="w-full flex flex-wrap">
        <div
          class="py-12 lg:px-40 px-20 lg:w-2/3 w-full bg-black h-104
          flex flex-col justify-center text-white"
        >
          <span class="text-3xl font-bold md:text-4xl">
            Advanced and predictive analytics support organisations to make better people and
            business decisions
          </span>
        </div>
        <img
          src="../assets/img/predictive_header.jpg"
          alt="picture"
          class="lg:w-1/3 w-full lg:h-104 h-auto text-lg"
        />
      </div>
    </div>
    <div class="flex flex-col items-center px-10 pt-8 pb-4">
      <div class="max-w-5xl w-full">
        <h2 class="text-2xl font-bold p-4 border-b-2 border-red-600">
          Predictive Analytics
        </h2>
        <!-- <img
          src="../assets/img/predictive1.jpg"
          alt="Predivive Banner"
          class="object-cover w-full lg:h-96 h-80 mt-4"
        /> -->
        <div class="flex flex-col">
          <p class="p-4">
            Data is key to the future. Leveraging on advanced technology and analytical expertise,
            MRC consultants can work closely with clients to draw business insights from massive
            data, provide various perspectives for HR professionals to assess and review business
            structure and productivity, therefore making efficient decisions to significantly
            improve morale and performance.
          </p>
          <p class="px-4 pt-4 pb-2">
            Some of your considerations may include:
          </p>
          <ul class="px-10 pb-2 list-disc text-red-700">
            <li>Predict individual resignations</li>
            <li>HR adjustable dashboards</li>
            <li>in-house HR analytics workshop</li>
          </ul>
          <p class="px-4 py-4"><strong>Examples of Employee Risk Predictor (ERP)
          module:</strong></p>
          <div class="flex flex-wrap justify-center gap-8 lg:px-12 md:px-8 px-4">
            <img
              src="../assets/img/erp_stimulate.jpg"
              alt="Employee Risk Predictor (ERP) Simulate"
              class="w-full"
            />
            <img
              src="../assets/img/erp_analytics.jpg"
              alt="Employee Risk Predictor (ERP) Analytics"
              class="w-full px-2"
            />
          </div>

          <p class="px-4 py-4"><strong>Examples of dashboards:</strong></p>
          <div class="flex flex-wrap justify-center gap-8 lg:px-12 md:px-8 px-4">
            <img
              src="../assets/img/predictive_dashboard1.png"
              alt="Expatriate Practice Banner"
              class="w-full"
            />
            <img
              src="../assets/img/predictive_dashboard2.png"
              alt="Expatriate Practice Banner"
              class="w-full px-2"
            />
          </div>
          <br />
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center">
      <div class="w-full">
        <div class="banner bg-cover bg-fixed bg-center py-4">
          <div
            class="flex flex-col bg-white bg-opacity-95 w-full max-w-5xl my-8
          bg-center mx-auto py-4"
          >
            <div class="flex flex-wrap bg-tools-bg1-50 bg-opacity-50 px-4 bg-center">
              <h2 class="text-2xl font-semibold mx-4 p-4 border-b-2 border-red-600 w-full">
                Predictive Analytics Service Offerings
              </h2>
              <div class="my-2 w-full lg:w-1/2" v-for="(svc, i) in predictiveServices" :key="i">
                <div class="hover:elevation-5 mx-4 hover:bg-white">
                  <h3 class="font-medium px-4 pt-3">{{ svc.title }}</h3>
                  <p class="px-4 pb-3">{{ svc.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.banner {
  background-image: url('../assets/img/predictive_banner.jpg');
  background-size: 100% 100%;
}
</style>

<script>
export default {
  name: 'People_Analytics',
  data() {
    return {
      predictiveServices: Object.freeze([
        {
          title: 'Employee Risk Predictor (ERP)',
          text:
            'Supervised Learning informing Employee Attrition - Which individual employees or which clusters have higher attrition risk? What are the potential science-based interventions to solve the attrition issues?',
        },
        {
          title: 'Employee Salary Prediction',
          text:
            'Machine-learned explanatory model for individual employee salary predictions based on Job, Incumbent & Performance Model and Labour Productivity',
        },
        {
          title: 'HR Reporting & Workforce Diagnosis',
          text:
            'How can HR leverage on existing talent data in the company? An effective talent metrics reporting strategy can help to provide timely, accurate and meaningful information for HR organisations and business functions',
        },
        {
          title: 'Training Workshops',
          text:
            'Predictive Analytics Training Workshop – predictive analytics applications from descriptive, predictive to prescriptive analytics. Power BI for HR Training Workshop – unlock analytical capabilities through Power BI',
        },
      ]),
    };
  },
  components: {},
};
</script>
