<template>
  <Modal
    :value="value"
    @input="$emit('input', $event)"
  >
    <div class="bg-white flex flex-col py-2 w-112 md:w-128 lg:w-192">
      <h1 class="font-semibold px-6 py-4 border-b flex justify-between items-center">
        Request for Preview of Latest Article
        <CloseBtn @click="$emit('input', false)" />
      </h1>
      <div class="w-full flex px-6 py-3">
        <div class="py-1 w-1/2 pr-2">
          <label
            for="reqname"
            class="block text-sm font-medium leading-5 text-gray-700"
          >Name</label>
          <input
            v-model="name"
            id="reqname"
            class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3
              border border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
              focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
        <div class="py-1 w-1/2 pl-2">
          <label
            for="aTitle"
            class="block text-sm font-medium leading-5 text-gray-700"
          >Title</label>
          <input
            v-model="title"
            id="aTitle"
            class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3
              border border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
              focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>
      <div class="w-full flex px-6 py-1">
        <div class="py-1 w-1/2 pr-2">
          <label
            for="aCompany"
            class="block text-sm font-medium leading-5 text-gray-700"
          >Company</label>
          <input
            v-model="company"
            id="aCompany"
            class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3
              border border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
              focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
        <div class="py-1 w-1/2 pl-2">
          <label
            for="reqemail"
            class="block text-sm font-medium leading-5 text-gray-700"
          >Company Email</label>
          <input
            v-model="email"
            id="reqemail"
            class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3
              border border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
              focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>
      <!-- <div class="w-full flex px-6 py-1">
        <div class="py-1 w-full">
          <label
            for="comments"
            class="block text-sm font-medium leading-5 text-gray-700"
          >Request</label>
          <textarea
            :value="request"
            @input="$emit('update:request', $event.target.value)"
            id="comments"
            class="w-full bg-gray-100 text-mt-1 form-input block py-2 px-3
              border border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
              focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            name=""
            cols="30"
            rows="5"
          ></textarea>
        </div>
      </div> -->
      <div
        v-show="errorMsg"
        class="text-red-600 px-6 py-2"
      >
        {{errorMsg}}
      </div>
      <div class="w-full flex flex-row-reverse px-6 pt-1 pb-2">
        <button
          @click="submitRequest"
          type="button"
          class="inline-flex justify-center py-2 px-4 border border-transparent
            text-sm leading-5 font-medium text-white bg-red-600
            focus:outline-none elevation-2 hover:elevation-6 active:elevation-1
            transition duration-150 ease-in-out"
        >Submit</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Vue from 'vue';
import Modal from '@/components/Modal.vue';
import isEmailValid from '@/util/isEmailValid';
import CloseBtn from '@/components/icons/CloseBtn.vue';
import { ebi } from '@/api/public/rpcs';
import recaptcha from '@/recaptcha';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
    },
  },
  methods: {
    submitRequest() {
      if (!this.name || !this.email || !this.company) {
        this.errorMsg = 'Please fill up your name, company and company email';
        return;
      }
      if (!isEmailValid(this.email)) {
        this.errorMsg = 'Please enter a valid company email';
        return;
      }
      this.errorMsg = '';

      recaptcha('ebiRequest')
        .then((token) => ebi({
          name: this.name,
          email: this.email,
          title: this.title,
          company: this.company,
          recaptchaToken: token,
        }))
        .then(() => {
          this.$emit('request-sent');
        })
        .catch((err) => {
          console.error(err);
          this.errorMsg = 'Error encountered. Please contact enquiry@mrc-asia.com for assistance';
        });
    },
  },
  watch: {
    requiredString() {
      if (!this.name || !this.email || !this.company) {
        this.errorMsg = 'Please fill up your name, company and company email';
        return;
      }
      if (!isEmailValid(this.email)) {
        this.errorMsg = 'Please enter a valid company email';
        return;
      }
      this.errorMsg = '';
    },
  },
  computed: {
    requiredString() {
      return `${this.name}|${this.email}|${this.company}`;
    },
  },
  data() {
    return {
      name: '',
      email: '',
      company: '',
      title: '',
      errorMsg: '',
    };
  },
  components: {
    Modal,
    CloseBtn,
  },
});
</script>
