<template>
  <div class="flex flex-col w-full">
    <div class="h-104 banner bg-cover bg-center relative">
      <div class="bg-black opacity-50 w-full h-full z-0 relative"></div>
      <div class="flex flex-col h-full items-center justify-center absolute inset-0">
        <div class="max-w-6xl w-full text-left flex">
          <h1 class="text-3xl md:text-4xl font-bold w-3/4 text-white px-8 py-0">
            Client data privacy & security
          </h1>
        </div>
      </div>
    </div>
    <div class="flex justify-center px-8 py-6">
      <div
        class="flex flex-col max-w-6xl rounded
        elevation-2 hover:elevation-6 items-center pb-4"
      >
        <div class="secure bg-cover bg-center h-24 w-full relative">
          <div class="bg-orange-100 opacity-90 w-full h-full z-0 relative"></div>
          <div
            class="flex flex-col h-full px-8 font-bold text-orange-900
              justify-center absolute inset-0"
          >
            <h2 class="text-2xl">Privacy Notice</h2>
          </div>
        </div>
        <div class="pt-8 pb-4 px-8 text-orange-900">
          <strong>MRC</strong> declares that all <strong>client data</strong> provided to us will be
          used for their intended purpose only. We will not alter, disclose, sell or transmit such
          data to any third party or on any public domain.
        </div>
        <div class="py-4 px-8 text-orange-900">
          All <strong>MRC</strong> employees and contractors are given access to our client data on
          a need-to-know and password-protected basis and have signed non-disclosure agreement with
          <strong>MRC</strong> as part of their employment or engagement terms and conditions. Any
          violation of confidentiality or abuse in use of such data are grounds for disciplinary
          action including instant dismissal.
        </div>
        <div class="py-4 px-8 text-orange-900">
          At <strong>MRC</strong>, we have put in place a number of industry standard security
          measures to make sure your data entrusted to us is safe from loss, misuse, or alteration.
          These include industry standard encryption, firewall and other data security practices.
          Our team reviews our security system regularly and upgrades it continuously to keep your
          data safe.
        </div>
        <div class="py-4 px-8 text-orange-900">
          By accepting the terms and conditions stated herein, you agree that
          <strong>MRC</strong> may collect and store your data entrusted to us in accordance with
          the applicable law.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner {
  background-image: url('../assets/img/market_insight_privacy.jpg');
}
.secure {
  background-image: url('../assets/img/cyber-security.jpg');
}
</style>
