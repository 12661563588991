<template>
  <div class="flex flex-col w-full">
    <div class="h-104 banner bg-cover bg-center relative">
      <div class="bg-black opacity-50 w-full h-full z-0 relative">
      </div>
      <div class="flex flex-col h-full items-center justify-center absolute inset-0">
        <div class="max-w-6xl w-full text-left flex flex-col">
          <h2 class="text-2xl md:text-3xl font-bold text-blue-300 px-8 py-0">
            Members Only
          </h2>
          <h1 class="text-3xl md:text-4xl font-bold text-white px-8 py-8">
            Seminars/Workshops/Symposium/Retail Forum Materials
          </h1>
        </div>
      </div>
    </div>

    <div class="w-full flex justify-center">
      <div class="flex flex-row overflow-x-auto justify-center max-w-5xl w-full px-2
      border-4 border-yellow-500 bg-gray-700">
        <div class="w-8 h-full flex justify-center">
          <button @click="goLeft()">
            <svg width="20px" height="20px" viewBox="-4 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
              <g id="Lager_13" data-name="Lager 13" transform="translate(-10 -6)">
                <path id="Path_15" data-name="Path 15" d="M15.909,16.681a1.97,1.97,0,0,
                1-.278-.732,1,1,0,0,1,.278-.679l5.517-5.732a2.116,2.116,0,0,0,
                .01-2.887l-.028-.03a1.958,1.958,0,0,0-2.854-.008l-8.267,8.613a1.077,
                1.077,0,0,0-.287.723,2.115,2.115,0,0,0,.287.775l8.267,8.665a1.959,
                1.959,0,0,0,2.854-.012l.028-.036a2.134,2.134,0,0,0-.01-2.9Z"
                fill="#ffffff"/>
              </g>
            </svg>
          </button>
        </div>
        <div ref="categoryPnl" class="w-full h-full
          flex justify-start flex-no-wrap overflow-x-auto overflow-y-hidden parent">
          <div
            class="w-1/2 md:w-1/3 flex-none h-full px-1 child"
            v-for="(category) in categories"
            :key="category"
          >
            <div
              class="h-full py-1
                text-gray-100 bg-gray-700
                hover:text-white hover:bg-gray-900 hover:font-bold hover:elevation-6
                active:elevation-1 transition duration-150 ease-in-out
                cursor-pointer font-medium
                items-center text-center flex flex-col justify-between"
              :class="category===selectedTabs?
                'font-bold text-yellow-500\
                 hover:text-yellow-500 bg-gray-900\
                  border-b-2 border-yellow-500':''"
              @click="selectedTabs=category"
            >
              <div class="">
                <FolderIcon :open="selectedTabs===category" />
              </div>
              <div class="w-full leading-tight px-8">{{category}}</div>
            </div>
          </div>
        </div>
        <div class="w-8 h-full flex justify-center">
          <button @click="goRight()">
            <svg width="20px" height="20px" viewBox="-4 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
              <g id="Lager_15" data-name="Lager 15" transform="translate(-9 -5)">
                <path id="Path_17" data-name="Path 17" d="M15.091,15.681a1.97,1.97,0,
                0,0,.278-.732,1,1,0,
                0,0-.278-.679L9.574,8.538a2.116,2.116,0,0,1-.01-2.887l.028-.03a1.958,
                1.958,0,0,1,2.854-.008l8.267,
                8.613a1.077,1.077,0,0,1,.287.723,2.115,2.115,0,0,1-.287.775l-8.267,
                8.665a1.959,1.959,0,0,
                1-2.854-.012l-.028-.036a2.134,2.134,0,0,1,.01-2.9Z" fill="#ffffff"/>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col items-center pb-6">
      <div class="flex flex-wrap max-w-5xl w-full items-stretch">
        <div
          class="w-1/2 md:w-1/3 p-2"
          v-for="(entry, i) in filteredSeminarEntries"
          :key="entry.title+i"
        >
          <div class="h-full p-4 hover:elevation-6 elevation-2 flex flex-col">
            <img
              :src="'/api/private/seminar/img/'+entry.imgPath"
              class="w-full border rounded-lg"
              style="max-height:10.5rem;"
            />
            <div class="flex justify-between pt-2">
              <button v-if="entry.showVideo"
                class="focus:outline-none bg-blue-600 px-4 py-1 text-white
                elevation-2 hover:elevation-6 active:elevation-1
                transition duration-150 ease-in-out"
                @click="watchVideo(entry)"
              >
                Video
              </button>
              <a v-if="entry.showPdf"
                :href="`/api/private/seminar/pdf/${entry.pdfPath}`"
                download
                class="focus:outline-none bg-blue-600 px-4 py-1 text-white
                  elevation-2 hover:elevation-6 active:elevation-1
                  transition duration-150 ease-in-out"
              >
                Slides
              </a>
            </div>
            <h3 class="text-orange-800 pt-3 pb-3 font-medium">{{entry.heading}}</h3>
            <div class="text-orange-900 font-bold text-lg leading-snug">
              {{entry.title}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-model="videoVisible">
      <div class="flex flex-col p-6 bg-white
          xl:w-286 lg:w-224 md:w-176 sm:w-144 w-112">
        <h2 class="text-lg font-bold pb-4">{{entry.title}}</h2>
        <div
          ref="videoDiv"
          class="w-full"
        >
          <video
            class="focus:outline-none w-full"
            controls
            preload="auto"
            ref="video"
          >
            <source
              ref="source"
              type="video/mp4"
            >
          </video>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store/index';
import { retrieveSeminarMaterials } from '../api/private/rpcs';
import FolderIcon from '../components/icons/FolderIcon.vue';
import Modal from '../components/Modal.vue';

export default Vue.extend({
  data() {
    return {
      selectedTabs: '',
      videoVisible: false,
      entry: {},
    };
  },
  methods: {
    watchVideo(entry) {
      this.videoVisible = true;
      this.entry = entry;
    },
    goLeft() {
      this.$refs.categoryPnl.scrollLeft -= 300;
    },
    goRight() {
      this.$refs.categoryPnl.scrollLeft += 300;
    },
  },
  computed: {
    seminarEntries: () => store.seminarEntries,
    filteredSeminarEntries() {
      const entries = this.seminarEntries;
      const selectedEntries = entries.filter((entry) => entry.category === this.selectedTabs);
      selectedEntries.sort((a, b) => a.order - b.order);
      return selectedEntries;
    },
    categories() {
      const catSet = new Set();
      this.seminarEntries.forEach((element) => {
        catSet.add(element.category);
      });
      const cats = Array.from(catSet);
      cats.sort((a, b) => {
        const aBig = a.releaseDate > b.releaseDate;
        if (aBig) {
          return 1;
        }
        return -1;
      });
      return cats;
    },
  },
  watch: {
    categories() {
      if (this.categories.length > 0) {
        [this.selectedTabs] = this.categories;
      }
    },
    videoVisible() {
      if (!this.videoVisible) {
        this.$refs.video.pause();
      }
    },
    entry() {
      if (this.entry.videoPath && this.videoVisible) {
        console.log(`/api/private/seminar/video/${this.entry.videoPath}`);
        this.$nextTick(() => {
          this.$refs.source.setAttribute(
            'src',
            `/api/private/seminar/video/${this.entry.videoPath}`,
          );
          this.$refs.video.load();
        });
      }
    },
  },
  components: {
    FolderIcon,
    Modal,
  },
  mounted() {
    retrieveSeminarMaterials().then((data) => {
      store.seminarEntries = data;
    });
  },
});
</script>

<style scoped>
.banner{
  background-image: url('../assets/img/seminar_member_macbook.jpg');
}
.parent {
  transform: rotateX(180deg);
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(200, 200, 200) rgb(50, 50, 50);
}
.child {
  transform: rotateX(180deg);
}

/* Works on Chrome/Edge/Safari */
.parent::-webkit-scrollbar {
  height: 8px;
}
.parent::-webkit-scrollbar-track {
  background: rgb(50, 50, 50);
}
.parent::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  border-radius: 2px;
  /* border: 1px solid blue; */
}
</style>
