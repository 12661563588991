<template>
  <div class="flex flex-col items-center">
    <div class="w-full flex flex-col">
      <div class="flex flex-wrap items-stretch">
        <div
          class="w-full p-1"
          v-for="item in onlineServices"
          :key="item.title"
        >
          <router-link
            :to="item.url"
            :target="item.newWindow?'_blank':'_self'"
            class="overflow-hidden h-full flex flex-row items-center
              border-b-2 active:elevation-1"
            :class="authorised(item)?
              'hover:elevation-8 hover:bg-gray-100 border-gray-400':
              'cursor-not-allowed pointer-events-none border-gray-100 bg-gray-100'"
          >
            <div
              class="w-10 h-10 rounded-full m-1"
              :class="authorised(item)? item.color: 'bg-gray-500'"
            >
              <img
                class="w-10 h-10 p-2"
                :src="item.img"
                :alt="item.title"
              >
            </div>
            <div class="pl-2 py-4">
              <div class="font-medium text-center text-base mb-1">{{item.title}}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store/index';

export default Vue.extend({
  computed: {
    permissions: () => store.user.permissions,
  },

  methods: {
    authorised(service) {
      if (this.permissions.includes('all')) {
        return true;
      }
      if (service.permissions.some((permission) => this.permissions.includes(permission))
      ) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      onlineServices: Object.freeze([
        {
          title: 'Executive Compensation Public',
          desc: '',
          url: '/exec-comp/',
          permissions: ['exec_comp_public'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/ec_public2.svg'),
          color: 'bg-red-100',
          newWindow: true,
        },
        {
          title: 'Executive Compensation Private',
          desc: '',
          url: '/exec-comp-private/',
          permissions: ['exec_comp_private'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/ec_private2.svg'),
          color: 'bg-yellow-200',
          newWindow: true,
        },
        {
          title: 'Super Compensation',
          desc: '',
          url: '/super-comp/',
          permissions: ['super_comp'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/supercomp.svg'),
          color: 'bg-green-300',
          newWindow: true,
        },
        {
          title: 'Employee Benefits',
          desc: '',
          url: '/employee-benefits/',
          permissions: ['employee_benefits'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/benefit2.svg'),
          color: 'bg-blue-400',
          newWindow: true,
        },
        {
          title: 'APAC Market Insights',
          desc: '',
          url: '/mi/',
          permissions: ['mi_survey'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/mi_survey.svg'),
          color: 'bg-indigo-400',
          newWindow: true,
        },
        {
          title: 'Sales Incentive Practices',
          desc: '',
          url: '/sip/',
          permissions: ['sip'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/sip.svg'),
          color: 'bg-purple-400',
          newWindow: true,
        },
        {
          title: 'Job Evaluation System',
          desc: '',
          url: '/jes/',
          permissions: ['jes'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/jes.svg'),
          color: 'bg-pink-400',
          newWindow: true,
        },
        {
          title: 'AI Market Sensor for HR',
          desc: '',
          url: '/aims/',
          permissions: [
            'aims.nonrewards',
            'mds',
            'forecast_calculator',
            'hr_markets',
            'pay_trends',
            'relocation',
            'legislations_news',
            'finance_productivity',
            'economy',
            'personnel-expense',
          ],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/aims.svg'),
          color: 'bg-red-400',
          newWindow: true,
        },
        {
          title: 'Employee Risk Predictor',
          desc: '',
          url: '/erp/',
          permissions: ['erp'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/risk.svg'),
          color: 'bg-yellow-300',
          newWindow: true,
        },
        {
          title: 'Monthly Market Insights',
          desc: '',
          url: '/market_insights_members/',
          permissions: ['contrarian_view', 'econ_guide', 'legis_guide'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/marketinsight.svg'),
          color: 'bg-green-200',
          newWindow: false,
        },
        {
          title: 'Seminars / Symposium / Retail Forum Materials',
          desc: '',
          url: '/seminar_members/',
          permissions: ['seminar_material'],
          // eslint-disable-next-line global-require
          img: require('@/assets/img/online/seminar.svg'),
          color: 'bg-blue-100',
          newWindow: false,
        },
      ]),
    };
  },
});
</script>

<style>

</style>
