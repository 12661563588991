<template>
  <div class="flex flex-col w-full">
    <div class="h-104 banner bg-cover bg-center relative">
      <div class="bg-black opacity-50 w-full h-full z-0 relative">
      </div>
      <div class="flex flex-col h-full items-center justify-center absolute inset-0">
        <div class="max-w-6xl w-full text-left flex flex-col">
          <h2 class="text-2xl md:text-3xl font-bold text-blue-300 px-8 py-0">
            Members Only
          </h2>
          <h1 class="text-3xl md:text-4xl font-bold text-teal-100 px-8 pt-8">
            Executive Business Intelligence
          </h1>
          <h1 class="text-3xl md:text-4xl font-bold text-teal-100 px-8 pt-4">
            Employment Legislation Update
          </h1>
          <h1 class="text-3xl md:text-4xl font-bold text-teal-100 px-8 py-4 pb-6">
            Economy / C&B Market Intelligence
          </h1>
        </div>
      </div>
    </div>

    <div class="w-full flex justify-center">
      <div class="flex flex-col overflow-x-auto justify-center max-w-5xl w-full px-2">
        <div class="w-full mt-4 mb-2 border-4 border-yellow-500 bg-gray-700
          flex justify-start flex-no-wrap overflow-x-auto overflow-y-hidden parent">
          <div
            class="w-1/2 md:w-1/3 flex-none h-full px-1 child"
            v-for="(category) in categories"
            :key="category.text"
          >
            <div
              class="h-full py-1
                text-gray-100 bg-gray-700
                hover:text-white hover:bg-gray-900 hover:font-bold hover:elevation-6
                active:elevation-1 transition duration-150 ease-in-out
                cursor-pointer font-medium
                items-center text-center flex flex-col justify-between"
              :class="tabClass(category)"
              @click="select(category)"
            >
              <div class="">
                <component :is="category.icon" />
              </div>
              <div class="w-full leading-tight px-8">{{category.text}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex justify-center">
      <div class="flex flex-col overflow-x-auto justify-center max-w-5xl w-full px-6">
        <div class="w-full my-2 border-4 border-red-600 bg-gray-700
          flex justify-start flex-no-wrap overflow-x-auto parent">
          <div
            class="w-40 flex-none h-full px-1 child"
            @click="selectedYear='all'"
          >
            <div
              class="h-full py-2
                text-gray-100 bg-gray-700
                hover:text-white hover:bg-gray-900 hover:font-bold hover:elevation-6
                active:elevation-1 transition duration-150 ease-in-out
                cursor-pointer font-medium
                items-center text-center flex flex-col justify-between"
              :class="yearClass('all')"
            >
              <div class="">
                <GlobeIcon />
              </div>
              <span class="leading-tight px-8">All</span>
            </div>
          </div>
          <div
            class="w-40 flex-none h-full px-1 child"
            v-for="(year) in years"
            :key="year"
            @click="selectedYear=year"
          >
            <div
              class="h-full py-2
                text-gray-100 bg-gray-700
                hover:text-white hover:bg-gray-900 hover:font-bold hover:elevation-6
                active:elevation-1 transition duration-150 ease-in-out
                cursor-pointer font-medium
                items-center text-center flex flex-col justify-between"
              :class="yearClass(year)"
            >
              <div class="">
                <CalendarIcon />
              </div>
              <div class="w-full leading-tight px-8">{{year}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col items-center pb-6">
      <div class="flex flex-wrap max-w-5xl w-full items-stretch px-4">
        <div
          class="w-1/2 md:w-1/3 p-2"
          v-for="(entry, i) in filteredMarketInsights"
          :key="entry.downloadKey+i"
        >
          <div class="h-full p-4 hover:elevation-6 elevation-2 flex flex-col items-center">
            <img
              :src="`/api/marketInsights/img/${selectedCategory.category}/${entry.img}`"
              class="w-5/6 border rounded-lg object-contain"
              style="max-height:20.8rem;"
            />
            <div class="w-full flex justify-end pt-2 px-6">
              <button
                @click="downloadPdf(`/api/private/marketInsights/pdf/${selectedCategory
                .category}/${entry.downloadKey}`)"
                download
                class="focus:outline-none bg-blue-600 px-4 py-1 text-white
                  elevation-2 hover:elevation-6 active:elevation-1
                  transition duration-150 ease-in-out"
              >
                Download
              </button>
            </div>
            <h3 class="w-full text-orange-800 pt-3 pb-3 font-medium">{{entry.edition}}</h3>
            <div class="w-full text-orange-900 font-bold text-lg leading-snug">
              {{entry.title}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Alert
      v-model="alertVisible"
      :timeout="8000"
    >
      <div class="px-6 py-4 lg:w-144 w-112 flex lg:text-base text-sm">
        <div class="pr-6">
          {{alertMsg}}
        </div>
        <button
          @click="alertVisible = false"
          class="text-pink-500 focus:outline-none font-medium text-sm"
        >CLOSE</button>
      </div>
    </Alert>
    <Alert
      v-model="downloadingVisible"
      :timeout="8000"
    >
      <div class="px-6 py-4 w-64 flex lg:text-base text-sm justify-around">
        <div class="">
          Downloading ...
        </div>
        <button
          @click="downloadingVisible = false"
          class="text-pink-500 focus:outline-none font-medium text-sm"
        >CLOSE</button>
      </div>
    </Alert>
  </div>
</template>

<script>
/**
 * on mount, retrieve
 */
import Vue from 'vue';
import store from '@/store/index';
import LibraryIcon from '../components/icons/LibraryIcon.vue';
import DocumentIcon from '../components/icons/DocumentIcon.vue';
import ScaleIcon from '../components/icons/ScaleIcon.vue';
import FolderIcon from '../components/icons/FolderIcon.vue';
import CalendarIcon from '../components/icons/CalendarIcon.vue';
import GlobeIcon from '../components/icons/GlobeIcon.vue';
import Alert from '../components/Alert.vue';
import { retrieveMarketInsights } from '../api/private/rpcs';

function getYears(marketInsights) {
  const yearSet = new Set();
  marketInsights.forEach((insight) => yearSet.add(insight.releaseDate.substr(0, 4)));
  const yearArr = Array.from(yearSet);
  yearArr.sort().reverse();
  return yearArr;
}

let filename = '';

export default Vue.extend({
  data() {
    return {
      downloadingVisible: false,
      alertVisible: false,
      alertMsg: '',
      selectedCategory: {},
      years: [],
      selectedYear: '',
      categories: [
        {
          text: 'ECONOMIC / C&B MARKET INTELLIGENCE',
          desc: 'Economy / C&B Market Intelligence guides',
          permission: 'econ_guide',
          category: 'econ_guide',
          icon: LibraryIcon,
        },
        {
          text: 'EXECUTIVE BUSINESS INTELLIGENCE',
          desc: 'Executive Business Intelligence articles',
          permission: 'contrarian_view',
          category: 'ebi',
          icon: DocumentIcon,
        },
        {
          text: 'EMPLOYMENT LEGISLATION UPDATES',
          desc: 'Employment Legislation Updates',
          permission: 'legis_guide',
          category: 'legislation',
          icon: ScaleIcon,
        },
      ],
    };
  },
  methods: {
    downloadPdf(url) {
      this.downloadingVisible = true;
      fetch(url)
        .then((response) => {
          this.downloadingVisible = false;
          if (response.status === 200) {
            filename = response.headers.get('content-disposition').substr(21);
            return response.blob();
          }
          if (response.status === 401) {
            throw new Error(
              'Sorry, your subscription does not include the access to download this article. Please contact us at enquiry@mrc-asia.com for more information.',
            );
          }
          throw new Error(
            'Error downloading article. Please contact us at enquiry@mrc-asia.com for more information.',
          );
        })
        .then((blob) => {
          const u = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = u;
          link.setAttribute('download', filename); // or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          this.alertMsg = err.message;
          this.alertVisible = true;
        });
    },
    select(category) {
      if (category.text === this.selectedCategory.text) {
        this.alertVisible = false;
        return;
      }
      if (this.permissions.includes(category.permission) || this.permissions.includes('all')) {
        retrieveMarketInsights(category.category).then((data) => {
          store.marketInsights = data;
          this.selectedCategory = category;
          this.alertVisible = false;
          this.years = getYears(data);
          [this.selectedYear] = this.years;
        });

        return;
      }
      this.alertVisible = true;
      this.alertMsg = `To access our ${category.desc}, please contact us at enquiry@mrc-asia.com`;
    },
    tabClass(category) {
      if (category.text === this.selectedCategory.text) {
        return 'font-bold text-yellow-500 hover:text-yellow-500 bg-gray-900 border-b-2 border-yellow-500';
      }
      return '';
    },
    yearClass(year) {
      if (year === this.selectedYear) {
        return 'font-bold text-red-400 hover:text-red-400 bg-gray-900 border-b-2 border-red-600';
      }
      return '';
    },
  },
  computed: {
    permissions: () => store.user.permissions,
    marketInsights: () => store.marketInsights,
    filteredMarketInsights() {
      if (this.selectedYear === 'all') {
        return this.marketInsights;
      }
      return this.marketInsights.filter(
        (insight) => insight.releaseDate.substr(0, 4) === this.selectedYear,
      );
    },
  },
  components: {
    FolderIcon,
    Alert,
    CalendarIcon,
    GlobeIcon,
  },
  mounted() {
    retrieveMarketInsights('ebi').then((data) => {
      store.marketInsights = data;
      this.select(this.categories[1]);
    });
  },
});
</script>

<style scoped>
.banner{
  background-image: url('../assets/img/market_insight_privacy.jpg');
}
.parent {
  transform: rotateX(180deg);
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgb(200, 200, 200) rgb(50, 50, 50);
}
.child {
  transform: rotateX(180deg);
}
/* Works on Chrome/Edge/Safari */
.parent::-webkit-scrollbar {
  height: 8px;
}
.parent::-webkit-scrollbar-track {
  background: rgb(50, 50, 50);
}
.parent::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200);
  border-radius: 2px;
  /* border: 1px solid blue; */
}
</style>
