<template>
  <div
    class="w-full testimonial-bg bg-cover bg-fixed bg-center flex flex-col py-0 items-center mt-20">
    <div class="max-w-10xl w-full bg-white bg-opacity-90 m-0">
      <h1 class="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl lg:text-5xl mb-5">
        Client Testimonials
      </h1>
      <Carousel
        :perPage="1"
        :scrollPerPage="false"
        :perPageCustom="[[768, 2], [1024, 3], [1280, 3], [1536, 4]]"
        :autoplay="true"
        :autoplayTimeout="3000"
        autoplayHoverPause
        :paginationEnabled="false"
        paginationColor="#cfcfcf"
        class="pr-6"
      >
        <Slide
          class="h-88 xl:h-112 overflow-y-auto pl-8 pr-2 pt-2"
          v-for="(item,i) in testimonials"
          :key="i"
        >
          <p class="font-medium text-gray-600 text-justify" v-if = "item.subheader" v-html="item.subheader">
          </p>
          <img v-if = "item.pollimage" v-bind:src= "require('../assets/img/' + item.pollimage)" />
          <p class="font-medium text-gray-600 text-justify" v-if = "item.words" v-html="item.words">
          </p>
          <p class="font-medium text-gray-600 text-justify" v-if = "item.words1" v-html="item.words1">
          </p>
          <p class="font-medium text-gray-600 text-justify" v-if = "item.words2" v-html="item.words2">
          </p>
          <p class="font-medium text-gray-600 text-justify" v-if = "item.words3" v-html="item.words3">
          </p>
          <p class="font-medium text-gray-600 text-justify" v-if = "item.words4" v-html="item.words4">
          </p>
          <span class="font-bold italic text-indigo-700">
            {{ item.from }}
          </span>
          <p class="text-right text-indigo-800 font-semibold pt-4">
            <i>{{ item.service }}</i>
          </p>
        </Slide>
      </Carousel>
      <!-- <div class="flex flex-col md:h-128 h-64 overflow-y-auto">
        <div
          class="px-5 py-4 mt-2"
          v-for="(item,i) in testimonialsSlice"
          :key="i"
        >
          <p class="px-3 font-medium text-gray-600">{{item.words}}
            <span class="font-bold italic text-indigo-700">
              - {{item.from}}
            </span>
          </p>
          <p class="text-right text-pink-600 font-semibold pt-2 pr-3">
            <i>{{item.service}}</i>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import breakpoints from '@/plugins/breakpoints';

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      breakpoints,
      startIndex: 0,
      testimonials: Object.freeze([
        {
          service: '2023 Long-term Incentive Plan (LTIP) seminar feedback',
          words1:
          '"Very good session and useful to my daily work" <b>(Ali Hu)</b><br><br>',
          words2:
          '"Good and pragmatic" <b>(Daniel Tan)</b><br><br>',
          words3:
          '"All Good" <b>(Anna)</b><br><br>',
          words4:
          '"Wonderful seminar and there were just so much information that the facilitator had provided.  It was difficult to design and develop such a seminar, thanks to Elaine for her great effort and time!" <b>(Istinna Lim)</b>',
          subheader:
          'Feedback received:<br>Majority of participants strongly agree or agree that this online seminar is engaging and effective',
          pollimage: '2023_LTI_PollResult.png',
        },
        {
          service: '2023 Power BI HR/C&B Analytics Workshop',
          words:
            'I find the course useful and practical for HR practicioners.  The trainers were also helpful and prompt in assisting with any issues faced.',
          from: 'Senior HR Manager',
        },
        {
          service: '2023 Power BI HR/C&B Analytics Workshop',
          words:
            'I want to thank both trainers who have displayed professionalism and patience in helping us troubleshoot. I appreciate the team’s effort to curate the training course materials / hands-on exercises.',
          from: 'Helena Oh, Senior C&B Manager, Sheares Healthcare',
        },
        {
          service: '2023 Power BI HR/C&B Analytics Workshop',
          words:
            'Thanks for the training which was very useful. The group case study was practical and the materials were very clear.',
          from: 'participant',
        },
        {
          service: '2023 Power BI HR/C&B Analytics Workshop',
          words:
            'This Power BI workshop sounds interesting and useful for HR Analytics.  I need to practice more to build up the confidence by using Power BI.  Thanks Kelvin and Yong Sheng for your guidance!',
          from: 'participant',
        },
        {
          service: '2023 Power BI HR/C&B Analytics Workshop',
          words:
            'very intensive and insightful knowledge. It can help me elevate my normal HR dashboard to next level.',
          from: 'participant',
        },
        {
          service: '2023 Power BI HR/C&B Analytics Workshop',
          words:
            'Thank you so much, Kelvin and Yong Sheng! This hands-on session has been very helpful to me, and I am eagerly looking forward to applying what I have learned to my daily analytics and insights.',
          from: 'participant',
        },
        {
          service: '2023 Power BI HR/C&B Analytics Workshop',
          words:
            'great session!',
          from: 'participant',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2022',
          words:
            'I find this course very useful. It gives us a very good idea of what diff plans look like as well as the key highlights for each one.  I would recommend this to anyone who would like to strengthen their C&B knowledge.',
          from: 'Senior Director, C&B, PVH',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2022',
          words:
            'The information shared during the seminar was definitely interesting and useful as usual.',
          from: 'Regional HR Manager, APAC , CGG',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2022',
          words:
            'I can tell there were tremendous efforts in preparations, collecting data, and putting everything together. Really appreciate all the market insights shared, latest market trending, macro-economic data, promotion/merit matrix, and how inflation impact merit budget decisions etc. The most impressive part was on Sales Incentive sharing, that was the most comprehensive sales incentive knowledge covered that I’ve ever seen! Again appreciate all the hard work and great sharing! Thank you!',
          from: 'Senior Manager, C&B, Tapestry',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2022',
          words:
            'I wish to inform you that the symposium is amazing.  I am also impressed by the rich content of all topics covered and speed of delivery.  It gives us good insight of the latest Total Rewards trends and keep abreast of the market development in the AP region.',
          from: 'Total Rewards Manager, Asia Pacific, Southco Asia Limited',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2022',
          words:
            'Thanks to Elaine and team for the 2-day symposium covering a wide spectrum of the latest top-of-mind HR topics! As usual, the presented information is well-researched and analysed together to provide a holistic view on how companies and its HR function are impacted by market trends. Highly recommended conference for total rewards professionals!',
          from: 'Senior Manager, Group Rewards, Singapore, SATS LTD',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2022',
          words:
            'MRC’s annual symposium is always a highlight for me.  The topics selected reflect current affairs and coupled with pragmatic approaches bring insights for the attendees.   The interactive breakup groups also allow us to know what other companies are doing.  Keep it up!',
          from: 'Total Rewards Director, Asia Pacific, Stanley Black & Decker',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2022',
          words:
            'Consistent with previous years, MRC never failed to impress its clients with their bite size updates of the economy, market pay practices and pay increments. It is nice that MRC has included benefits trends in this year’s symposium.',
          from: 'Asia Compensation & Benefits, Air Products',
        },
        {
          service: 'Designing Salary & Bonus Seminar 2022',
          words:
            'Thank you for organizing the online seminar on designing salary and bonus. This is a great course for anyone in the compensation stream and also good background knowledge for those who like to learn more. It offers in-depth know-how of technical terms, examples, case studies and practical exercises to keep participants engaged. It helped me think about our own compensation strategy and how to design a framework that is fit for use.<br>The decision made to attend this seminar is a right one and definitely would recommend this to HR and/or compensation peers.<br>*Note: Elaine has such great experiences and knowledge (like a walking compensation database) and it will be a pity not to share.',
          from: 'VP Performance & Reward, Malaysia',
        },
        {
          service: 'Designing Salary & Bonus Seminar 2022',
          words:
            'The seminar was very comprehensive and useful to my work. I like the case studies a lot as they facilitated us to apply our learning. After completing the exercise, it reinforced my understanding on the techniques for designing pay structure, bonus scheme.',
          from: 'Senior C&B Analyst, Hong Kong',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2021',
          words:
            'Elaine and her team never fail to impress their clients with their detailed agenda and deep research and findings. Much of material is testament of their many hours of sweat and hard work. The symposium is truly value for money considering the depth, breadth and currency of their presentation materials. Most excellently is that Elaine and team is able to deliver their sharing in clear and concise manner, peppered with their personal anecdotes and this resonated with the participants greatly.',
          from: 'Director, Total Rewards Asia, Singapore',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2021',
          words:
            'As usual, I found the symposium very informative and will definitely join in the future.',
          from: 'C&B, HR - Asia, Celestica, Malaysia',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2021',
          words:
            'The sessions were very good.. hit all the high notes for me.. LTI, high Tech, benchmarking and also the 2022 forecast!!! ROI consistently high!!',
          from: 'Puay Ser, Director - Total Rewards (APAC & ME), Cognizant, Singapore',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2021',
          words:
            'Thanks so much! One of the best symposiums I’ve ever attended.',
          from: 'Joyce Lek, Deputy Director, Strategic Leadership (Sun Ray & Boards), Sector Capability & Transformation Group, NCSS, Singapore',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2021',
          words:
            'Thanks Elaine and team to provide such an informative with in-depth market trend analysis in the two days Symposium.  You are always a great partner to work with and providing an one-stop consultation and solution to the business.  Thanks.',
          from: 'Tracy Wong, Senior Director, Human Resources, Hong Kong',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2021',
          words:
            'I enjoyed and learnt a lot from symposium very much.',
          from: 'Antonia Chan, Director, C&B, Hong Kong',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The sessions are indeed well crafted and insightful. Lots of research have been put into the content. Even though it is MRC’s 1st virtual session via zoom, the session is well managed. The facilitator was able to respond to my query in regards to sound glitch. I like it that it was done without music fanfare and we are able to get to the stuff we sign up for. I have attended other online sessions where the speaker did not present until a 7 minutes music fanfare playing in the background. Those sessions are a drag. Overall, it is great customer experience even though it is conducted online.',
          from: 'Director, Total Rewards Asia',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'I have always enjoyed attending the annual symposium organized by Elaine and team. Your coverage of topics and insights are always so up-to-date and timely. Sometimes it even surprises me because I am working on that same topic and there you are sharing the information in your session. The sharing and materials are all very well prepared and useful. That is why I never fail to attend any of your sessions every year, no matter how busy I am. You have my support for MRC and pls keep up the good work and stay healthy and motivated during this difficult time.',
          from: 'Global Rewards & Talent Attraction, Asia',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The annual conference is a one-stop shop to get all information required to make executive decision on salary planning for the following year. The updates on all locations within Asia Pacific labour market and general economy give me an overview in a snapshot. The sharing of new technology and AI keeps me abreast of new development in the HR arena and equips me with the necessary tools required. Thank you to the working team who put together the conference material. It is an occasion and ritual not to miss every year.',
          from: 'Director, Group Human Resources, China & Hong Kong',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'Great coverage as always! Every MRC symposium is very well planned; with very comprehensive information and great insights into the latest market and economic trends. The Asia Compensation Trend update and outlook slides are also very beneficial to the compensation analyst/specialist. Would not want to miss the annual symposium! Will definitely recommend the symposium to every HR Professional. Thank you for organizing and special thanks to all the speakers!',
          from: 'Director, Total Rewards & Projects',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The two-day MRC symposium this year, although online, delivered the same great value to me as previous years’. We knew this year’s scheme will be the pandemic but the amount of data presented, the way it was organized and how case study was elaborated around it is obviously more and deeper than any other sharing/discussion I have participated before. I can clearly feel the amount of work behind the presentation MRC team put together. I enjoyed seeing in many sessions MRC team members’ smiling faces as well. Through the screens I can feel their enthusiasm. It is precious in these gloomy days. By the way, saved videos are very helpful. I missed your last overall market sharing session due to its conflict with another meeting. But now I can view the video and I have nothing missing! Have said these, let’s wish for an offline symposium next year!',
          from: 'Head of C & B, AP',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'A very fruitful online seminar. The speakers have brought us valuable insights in total rewards in this unprecedented economic situation.',
          from: 'Director, Group Human Resources',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'Many thanks to you and your team for another great symposium! Although it had to be done differently from the past face to face, trust the amount of time, preparation, testing etc would be the same if not more. Very insightful, powerpack with details and data and best of all as a client, there’s no disappointment or ask for more.',
          from: 'C & B Director, APAC',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'Congratulations for the extremely successful 2020 symposium, Elaine! As always, the symposium is very inspirational, informative, and practical. It is very special this year, by using Zoom, 90+ participants joined the session at the same time and MRC managed the session very interactive. I stayed for two full days from beginning to the end with no missing session. And I found the sessions generated lots of thinking with forward looking instruction, like Where to Work, Locating the next Factory. I also like the Country Outlook session that provides ready-to-use information that I can share with my colleagues and leaders.',
          from: 'Whitney Yu, C & B Director, AP, Honeywell',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The 2-day symposium was awesome and you provide a lot of insight and good reference on market prediction which other HR consultancy would not make it.',
          from: 'Director, C&B',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'I must say overall the symposium was a success. Well done and excellent job to you and your team! I must specifically give the team high commendation on the contents of the presentation which were so rich. I am sure the others will concur with me that the presentations, sharing and materials were of great substance and extremely useful for us to take back to our organizations. We could imagine the tremendous amount of time and efforts put in to the preparation and to organizing the event itself. A very satisfied participant indeed!',
          from: 'Regional C & B Director, Asia',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'As always, Elaine and the MRC team provided holistic market data, insights and trends from different perspectives (macro-economic, compensation, benefit, global mobility and many others). I also appreciate the prompt real time response by MRC team to participants\' questions during the session which made it very interactive though this is the first year to have an online symposium. If you are holding a regional rewards or HR role, this is the best fit for you.',
          from: 'Director – People Operations & Rewards',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'It’s a good 2-day sharing session. Despite the challenges of having it remotely, there is no compromise on content with case studies, hot topics, trend analysis, interactive sharing among participates… a lot of insightful information worth for considerations and reference.  Good job!',
          from: 'Senior Director, Comp. & People Ops.',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'MRC\'s 2-day symposium was highly informative and captivating. Elaine is a truly passionate and experienced speaker. She shared with us market insights, her experience with lots of practical examples/tips. Look forward to the symposium again next year!',
          from:
            'Jenny Lam, Senior Manager – Global Rewards, Lee Kum Kee International',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'Yes! I really appreciate the 2-day event which brings me tremendous information. The detailed analysis really helps me to understand the Asian markets well. The professionalism of MRC teams, especially of Elaine, really impress me a lot.',
          from: 'Compensation & Benefits, HRIS Manager',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'Thanks MRC for organizing the symposium, the 2 days session is really great sharing with lots of useful info. It not only shares market data, but also deeply analysis and insightful observation for economy, industry, as well as HR territory, which we will never get from any other consulting firms’ sharing.',
          from: 'C & B Director',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The 2 days symposium has covered a wide scope of topic. And it is impressive that it provides an in-depth analysis and valuable foresight of the topics for professional HR.',
          from: 'Director, HR',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'This two-day symposium is really  great. In addition to a large amount of detailed data and analysis, the “Real time dashboard” session also left a deep impression on me. C&B needs to keep pace with the times. I hope there will be more training or activities on this in the future.',
          from: 'C & B Manager',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The MRC symposium is a great opportunity when we can be provided with so many market insights and practices. This is instrumental and serves as a vital reference when we want to make an important decision in the Human Resource domain. We have benefited a lot from the symposium and I look forward to more market information as well as other HR expertise from MRC in future.',
          from: 'HR Business Partner',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The annual MRC event is one which I will not missed. The depth of information, from macroeconomics to how it impacts HR, to insights on best practices and even options to handle different situations is something which we could definitely bring back to our daily HR lives. Their sharing is rooted from the vast experiences of Elaine and team is what we call "real" HR sharing, not something philosophical or motherhood statements. The breakout discussions they facilitated provides good exchange of practices and networking opportunities. Due to COVID, we had to have 2 full days on-line sessions instead, but nevertheless still as excellent. Thank you.',
          from: 'Rewards Director',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'This symposium was a great success. All presentations by you and your team members are so impressive and insightful. The video recording is definitely an unexpected gift. Thank you and your team for all the effort. My best wishes to MRC!',
          from: 'Senior C&B Manager',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'Like every year the MRC Symposium never disappoints. Very informative sessions, especially on how COVID-19 has impacted businesses and how businesses are responding to it. Although this year we didn’t get to network with each other at a venue like before, I love how MRC has arranged online breakout sessions for participants to share their best practices under the challenging environment. Highly recommend the Symposium to anyone who would like to learn more about the changing economy and market updates.Thanks again to Elaine and the entire MRC team for their great work!',
          from: 'Teamhead People & Organization',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'I think the 2-day MRC conference was very informative and insightful, reminded me of previous HRBS conference. Some comments to share: MRC 2020 Symposium is a great conference out of few must-attend event this year. Well organized virtual conference with an easy tool to access. The 2-day MRC event was packed with so much up-to-date topics and informative presentations. Great speakers and superb quality of contents, offering valuable insights and best practices. I would unhesitatingly recommend for Rewards professionals to attend.',
          from: 'Director, Asia Rewards',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            '2020 symposium was a very special one. Despite it was online instead of face to face, I could see Elaine and her team had put in much more effort than before. The symposium provided me a lot of market insights under COVID 19 condition that could help me to meet the challenges ahead.',
          from: 'HR Director',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'The sessions are really impressive and informative. There were so many sharp and forward looking insights which I believe are quite impactful and powerful for HR professional to think/use in the future. Highly recommend HR professionals to join such event, these are great sessions and worthy to join!',
          from: 'Head of C&B',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'Very informative and insightful session! The 2-day symposium provides very detailed, in-depth analysis on latest situation and trends from multiple prospective supported by strong data. Every time joining their session is like a good revision from the past learnt and developing something new for the future. I truly find it very inspiring and helpful at work!',
          from: 'AVP, Global Rewards',
        },
        {
          service: 'Total Rewards & Predictive Analytics Symposium 2020',
          words:
            'I am writing this testimonial to thank you for the splendid 2-day online symposium conducted on 24-25 September, 2020 by MRC. Having joined this annual event for several years, I feel not only the event conducted in each year was organized in an extremely professional & effective manner, but all the information shared about the business / economy / markets / industry, etc. gave me a comprehensive picture as well as many future insights. Although this year’s symposium was conducted through online, the material that provided and the discussions that were conducted were kept utmost effective and motivating. What I personally like about were those easy to understand analysis and conclusion for every part. I would like to thank you for conducting all the sessions and providing me a great learning experience. I look forward the attend your future seminars as well, if possible.',
          from: 'Director of People & Organization, Asia Pacific',
        },
      ]),
    };
  },
  computed: {
    testimonialsSlice() {
      let mult = 1;
      if (this.breakpoints.is === 'lg' || this.breakpoints.is === 'xl') {
        mult = 3;
      } else if (this.breakpoints.is === 'md') {
        mult = 2;
      } else {
        mult = 1;
      }

      const temp = [];
      let x = [];
      this.testimonials.forEach((t, i) => {
        x.push(t);
        if ((i + 1) % mult === 0 || i === this.testimonials.length - 1) {
          temp.push(x);
          x = [];
        }
      });
      // console.log(temp);
      return temp;
    },
  },
  methods: {},
};
</script>

<style scoped>
.testimonial-bg {
  background-image: url('../assets/img/testimonial-bg.jpg');
}
</style>
