<template>
  <div class="flex flex-col">
    <div class="flex flex-wrap items-stretch">
      <div class="lg:w-7/12 w-full bg-black text-white flex items-center justify-center">
        <div class="md:p-16 lg:p-20 xl:p-24 p-12 flex flex-col">
          <div class="py-4 text-4xl font-bold">{{ title.text }}</div>
          <div class="py-4 text-lg font-medium">
            {{ title.edition }} | Executive Business Intelligence monthly newsletter
          </div>
          <div class="pt-5 flex">
            <div
              @click="requestModalVisible = true"
              class="bg-red-600 px-4 py-2 text-white text-sm font-medium
            focus:outline-none hover:bg-red-500 active:bg-red-700 cursor-pointer"
            >
              PREVIEW LATEST ARTICLE
            </div>
          </div>
          <div class="pt-6 flex">
            <div
              @click="memberGoto($router, '/market_insights_members')"
              class="bg-red-600 px-4 py-2 text-white text-sm font-medium
            focus:outline-none hover:bg-red-500 active:bg-red-700 cursor-pointer"
            >
              MEMBERS
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-5/12 w-full">
        <img
          class="object-cover w-full h-full"
          src="@/assets/img/marketinsights_banner.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="py-3">
      <div class="flex flex-col" id="executive_biz_intelligence">
        <div class="w-full max-w-5xl py-6 px-4 self-center">
          <h2 class="text-2xl font-semibold p-4 border-b-2 border-red-600">
            Executive Business Intelligence
          </h2>
          <p class="p-4">
            <strong>Monthly update of C&B/HR trends</strong>
            , prevailing in APAC, backed up by reliable research & survey data from MRC Asia and relevant sources.
          </p>
          <p id = 'demoid'></p>
          <div class="flex flex-wrap px-2 items-stretch">
            <div
              class="lg:w-1/2 w-full p-2 min-h-0"
              v-for="article in first2Articles"
              :key="article.key"
            >
              <div class="h-full flex flex-col p-8 elevation-2 hover:elevation-6 justify-between">
                <div>
                  <h3 class="text-orange-800 font-semibold pb-4">
                    {{ article.month }}
                  </h3>
                  <div class="text-orange-900 font-bold pb-4">{{ article.title }}</div>
                </div>
                <!-- <div class="leading-snug text-sm text-justify">{{ article.keytakeaway }}
                </div> -->
                <div class="flex flex-col">
                  <div class="flex flex-col items-center">
                    <img :src="getImageURL(article.covershot)"
                    class="w-2/4" alt="" />
                  </div>
                  <router-link
                    :to="'/market_insights/ebi/' + article.key"
                    class="text-blue-600 text-sm font-semibold tracking-tighter
                  pr-2 pt-4 text-right hover:underline cursor-pointer"
                  >
                    Read more...
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end pr-4 pt-2 text-right cursor-pointer">
            <router-link
              to="/market_insights/ebi"
              class="px-6 py-4
                tracking-tighter bg-blue-700 text-white font-semibold
                elevation-2 hover:elevation-6 active:elevation-1
                transition duration-150 ease-in-out"
            >
              MORE ARTICLES
            </router-link>
          </div>
        </div>
      </div>

      <div class="flex flex-col" id="employment_legislation_updates">
        <div class="w-full max-w-5xl py-6 px-4 self-center">
          <h2 class="text-2xl font-semibold p-4 border-b-2 border-red-600">
            Employment Legislation Updates
          </h2>
          <p class="p-4">
            <strong>Monthly update of employment law changes</strong>, covering Asia Pacific,
            Americas, Europe.
          </p>
          <div class="overflow-x-auto">
            <img style="min-width:100%" src="@/assets/img/elu.jpg" alt="" />
          </div>
        </div>
      </div>

      <div class="flex flex-col" id="market_insights">
        <div class="w-full max-w-5xl py-6 px-4 self-center">
          <h2 class="text-2xl font-semibold p-4 border-b-2 border-red-600">
            C&B Market Insights Guides
          </h2>
          <div class="p-4">
            Bi-annual guides providing market insights on:
            <ul class="list-inside list-disc">
              <li>APAC economics (GDP, inflation, unemployment, FDI, PMI) &</li>
              <li>
                HR guide (pay increase, attrition, workforce demand, bonus, fresh grad start rates)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <RequestArticleModal v-model="requestModalVisible" @request-sent="requestSent" />
    <Alert bottom :timeout="8000" v-model="requestAlert">
      <div class="px-6 py-4 lg:w-144 w-112 flex lg:text-base text-sm justify-between">
        <div class="pr-6">
          {{ requestMsg }}
        </div>
        <button
          @click="requestAlert = false"
          class="text-pink-500 focus:outline-none font-medium text-sm"
        >
          CLOSE
        </button>
      </div>
    </Alert>
  </div>
</template>

<script>
import Vue from 'vue';
import memberGoto from '@/util/memberGoto';
import articles from '@/assets/articles/articles.json';
import articles2 from '@/assets/articles/articles2.json';
import articles3 from '@/assets/articles/articles3.json';
import articles4 from '@/assets/articles/articles4.json';
import articles5 from '@/assets/articles/articles5.json';
import articles6 from '@/assets/articles/articles6.json';
import RequestArticleModal from '@/components/RequestArticleModal.vue';
import Alert from '@/components/Alert.vue';

export default Vue.extend({
  methods: {
    memberGoto,
    requestSent() {
      this.requestModalVisible = false;
      this.requestMsg = 'Your request is sent successfully! If you do not receive the article in 15 minutes, please contact enquiry@mrc-asia.com.';
      this.requestAlert = true;
    },
    getImageURL(covershot) {
      return `/api/marketInsights/img/ebi/ContrarianView_${covershot}.png`;
    },
  },
  data() {
    return {
      requestAlert: false,
      requestMsg: '',
      requestModalVisible: false,
      articles: {
        ...articles6, ...articles5, ...articles4, ...articles3, ...articles2, ...articles,
      },
      title: {
        text:
          'To keep executives motivated and satisfied, it is important to provide them with the right remuneration package',
        // edition: 'September 2020',
        edition: '',
      },
    };
  },
  computed: {
    first2Articles() {
      return Object.keys(this.articles)
        .slice(0, 2)
        .map((key) => ({
          ...this.articles[key],
          key,
        }));
    },
  },
  components: {
    RequestArticleModal,
    Alert,
  },
});
</script>

<style scoped></style>
