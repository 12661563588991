<template>
  <div class="w-full testimonial-bg bg-cover bg-fixed bg-center flex flex-col py-4 items-center">
    <div class="max-w-6xl w-full bg-white bg-opacity-90 m-8 pb-4">
      <h1 class="text-3xl font-semibold p-8 text-center">
        Book Review
      </h1>
      <Carousel
        :perPage="1"
        :scrollPerPage="false"
        :perPageCustom="[[768, 2], [1024, 3]]"
        :autoplay="true"
        :autoplayTimeout="3000"
        autoplayHoverPause
        :paginationEnabled="true"
        paginationColor="#cfcfcf"
        class="pr-6"
      >
        <Slide
          class="h-88 xl:h-112 overflow-y-auto pl-8 pr-2 pt-2"
          v-for="(item,i) in testimonials"
          :key="i"
        >
          <p class="font-medium text-gray-600 text-justify">{{item.words}}
          </p>
          <span class="font-bold italic text-indigo-700">
              - {{item.from}}
          </span>
          <p class="text-right text-pink-600 font-semibold pt-4">
            <i>{{item.service}}</i>
          </p>
        </Slide>
      </Carousel>
      <!-- <div class="flex flex-col md:h-128 h-64 overflow-y-auto">
        <div
          class="px-5 py-4 mt-2"
          v-for="(item,i) in testimonialsSlice"
          :key="i"
        >
          <p class="px-3 font-medium text-gray-600">{{item.words}}
            <span class="font-bold italic text-indigo-700">
              - {{item.from}}
            </span>
          </p>
          <p class="text-right text-pink-600 font-semibold pt-2 pr-3"><i>{{item.service}}</i></p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import breakpoints from '@/plugins/breakpoints';

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      breakpoints,
      startIndex: 0,
      testimonials: Object.freeze([
        {
          service: 'President & Chief Executive Officer, AIRINC, Cambridge, MA, USA',
          words:
            'I have known Elaine for many years.If you are wanting to improve your businessacumen or working through personal challenges, you will learn from an incredible person who has been through all. She has reached the pinnacle of the highest moutains by conquering the deepestof valleys. Elaine NEVER gives up no matter the obstacles or successes.',
          from: 'Steve Brink',
        },
        {
          service: 'Vice President, Global Total Rewaards / People Technology, NASPERS, Amsterdam, Netherlands',
          words:
            'Elaine interviews the personal with the professional in this book. She has overcome cancer and built a business from scratch then sold it and since created a new company. Her story conveys tenacity, perseverance and true grit in the midst of tragedy.',
          from: 'Michael A. Piker',
        },
        {
          service: 'Managing Partner, SparkChief & Co., Switzerland',
          words:
            'This book is a survivor\'s story. Elaine is constantly seeking new frontiers and pushing for transformation. This new book unearths some of the secrets of her success as wellas her strategic approaches to overcome obstacles.',
          from: 'Ali Kursun',
        },
        {
          service: 'Director of Compensation, Greater China, Amway, Hong Kong SAR, China',
          words:
            'Management Consultant, Compensation Professional, Teacher... are how people refer to Elaine. I have known Elaine for more than 10 years as an exceptional consultant. She developed her own consulting tools, turned data into insightful analysis and trained a team of professionals. Her seminars sharpen not only skillsets but mind. What impressed me most is her upholding of integrity - this is a true professional!',
          from: 'Grace Lam',
        },
        {
          service: 'Director, Asia Pacific Compensationand Benefit, Honeywel, Beijing, China',
          words:
            'Elaine recruited me into CRG in 1999, which opened a door for me into the consulting world. She has been my leader, coach and mentor for over the years. She always surprises me with innovative ideas and execution capability. To this day, her incredible story continues to inspire me to overcome obstacles and challenges. I am proud to say that she serves as a role model for me and all those who know her.',
          from: 'Wencui (Whitney) Yu',
        },
        {
          service: 'President & CEO ASPIAL, Singapore Council Member (59th Council, 2017-2019), Singapore Chinese Chamber of Commerce & Industry',
          words:
            'It is no small feat for Elaine Ng, a Singaporean to start her consulting business in Hong Kong, grew that to its height with four offices in Hong Kong, Singapore, Shanghai and Beijing, and then sold it to the world\'s largest HR consulting firm. Yetshe started another business after selling her first! She is a beacon of strength and stands out among equals.',
          from: 'Koh Wee Seng',
        },
      ]),
    };
  },
  computed: {
    testimonialsSlice() {
      let mult = 1;
      if (this.breakpoints.is === 'lg' || this.breakpoints.is === 'xl') {
        mult = 3;
      } else if (this.breakpoints.is === 'md') {
        mult = 2;
      } else {
        mult = 1;
      }

      const temp = [];
      let x = [];
      this.testimonials.forEach((t, i) => {
        x.push(t);
        if ((i + 1) % mult === 0 || i === this.testimonials.length - 1) {
          temp.push(x);
          x = [];
        }
      });
      // console.log(temp);
      return temp;
    },
  },
  methods: {},
};
</script>

<style scoped>
.testimonial-bg{
  background-image: url('../assets/img/testimonial-bg.jpg');
}
</style>
