<template>
  <div class="flex flex-col w-full">
    <div class="h-104 banner bg-cover bg-center relative">
      <div class="bg-black opacity-50 w-full h-full z-0 relative"></div>
      <div class="flex flex-col h-full items-center justify-center absolute inset-0">
        <div class="max-w-6xl w-full text-left flex">
          <h1 class="text-3xl md:text-4xl font-bold w-3/4 text-white px-8 py-0">
            Grow with us
          </h1>
        </div>
      </div>
    </div>

    <div class="flex justify-center px-8 py-6">
      <div
        class="flex flex-col max-w-6xl rounded
        elevation-2 hover:elevation-6 pb-4 w-full"
      >
        <div class="hire bg-cover bg-center h-28 w-full relative flex border-b">
          <img src="../assets/img/hiring.jpg" alt="" class="object-cover w-1/2" />
          <h2 class="text-2xl font-bold px-12 self-center text-green-900">How we hire?</h2>
        </div>

        <div class="pt-8 pb-4 px-8 text-green-900">
          If you’ve excelled in school and aspire to work with the smartest, the most humble and the
          most hardworking people, we are interested to meet you. This is a unique opportunity to
          join a young team of intellectuals who embrace and enjoy working together beyond 9-5 and
          sometimes during weekend. Yet, everyone is motivated by a sense of camaraderie at work and
          by an exciting mission to unleash the power of artificial intelligence in human resources
          management consulting.
        </div>
      </div>
    </div>

    <div class="flex justify-center px-8 py-6">
      <div
        class="flex flex-col max-w-6xl rounded
        elevation-2 hover:elevation-6 pb-4 w-full"
      >
        <div class="hire bg-cover bg-center h-28 w-full relative flex border-b">
          <h2 class="text-2xl font-bold px-12 text-right self-center text-green-900 w-1/2">
            CONSULTING ANALYST
          </h2>
          <img src="../assets/img/consulting.jpg" alt="" class="object-cover w-1/2" />
        </div>

        <div class="pt-4 pb-4 px-8 text-green-900">
          <h3 class="font-bold text-lg pb-2">We seek:</h3>
          <ul class="space-y-2 list-inside list-disc">
            <li>
              1st class graduate in Engineering, Computer Science/Engineering or other quantitative
              disciplines.
            </li>
            <li>Advanced skills in using Excel and PPT, VBA and/or Power BI are required.</li>
            <li>0-1 year working experience; read/write Chinese advantageous</li>
          </ul>

          <h3 class="font-bold text-lg pt-6 pb-2">The job:</h3>
          <ul class="space-y-2 list-inside list-disc">
            <li>
              Massive data research, cleaning, analysis, and interpretation of trends and findings
            </li>
            <li>Carries out e-surveys among clients for market insights on hot topics</li>
            <li>Performs client services and follow-up to meet service deliverables</li>
            <li>Support consultants in project work such as researching topics, etc.</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex justify-center px-8 py-6">
      <div
        class="flex flex-col max-w-6xl rounded
        elevation-2 hover:elevation-6 pb-4 w-full"
      >
        <div class="hire bg-cover bg-center h-28 w-full relative flex border-b">
          <img src="../assets/img/compensation.jpg" alt="" class="object-cover w-1/2" />
          <h2 class="text-2xl font-bold px-12 self-center text-green-900">In return, we offer:</h2>
        </div>

        <ul class="pt-8 pb-4 px-8 text-green-900 space-y-2 list-inside list-disc">
          <li>
            Quick pay increase twice a year pacing your contributions
          </li>
          <li>Fast growth to become consultant and above</li>
          <li>
            Work in an entrepreneurial, fast-paced environment with smart, hardworking & caring
            teammates
          </li>
          <li>Interesting and cutting-edge projects</li>
        </ul>
        <div class="py-4 border-t text-red-600 px-8">
          Please send your CV and cover letter to
          <a class="font-semibold hover:font-bold" href="mailto:apply@mrc-asia.com"
            >apply@mrc-asia.com</a
          >. Applications will be reviewed on a rolling basis. Only shortlisted applicants will be
          notified.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner {
  background-image: url('../assets/img/join.jpg');
}
</style>
