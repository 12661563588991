<template>
  <div class="flex flex-col items-center">
    <!-- Hero section with background image, heading, subheading and button -->
    <div
      class="relative overflow-hidden bg-cover bg-no-repeat w-full hero-bg"
      style="
        height: 150px;
      ">
      <div
        class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
        style="background-color: rgba(255, 255, 255, 0.15)">
      </div>
    </div>
    <div class="w-full">
      <div class="flex h-full items-center justify-start m-auto max-w-7xl">
        <div class="relative -mt-16 px-2 py-4">
          <div
            class="w-96 block rounded bg-white p-6 dark:bg-neutral-700 multi-shadow">
            <h1 class="font-semibold text-2xl p-4">References</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero section with background image, heading, subheading and button -->
    <div class="w-full flex flex-col max-w-7xl py-10">
      <div class="grid grid-cols-3 items-stretch">
        <div class='flex flex-col items-center justify-center h-full text-center sm:flex-row sm:justify-start sm:text-left'>
          <div
            class='block px-4 py-1 rounded-lg hover:border hover:border-pink-200 hover:border-b-0 hover:shadow-lg border mx-2 my-5'
            v-for='item in downloadItems'
            :key="item.title"
          >
            <img
              class='flex-shrink-0 object-cover object-center w-12 h-12 mb-4 rounded-lg sm:mb-0'
              :class="authorised(item)? '': 'bg-gray-500'"
              :src='item.icon'
              :alt='item.title'
            />
            <div class='flex-grow w-12/12 sm:pl-8'>
              <h2 class='text-lg font-medium text-gray-900 title-font'>
                {{ item.title }}
              </h2>
              <div class="flex w-full">
                <img :src='item.img' class="w-full" />
              </div>
              <div class="flex float-right">
                <div v-if='canDownload(item)'>
                  <router-link
                    :to='item.url'
                    :target="item.newWindow?'_blank':'_self'"
                    :class="canDownload(item)?
                        '':
                        'cursor-not-allowed pointer-events-none border-gray-100 bg-gray-100'"
                  >
                    <div class="mx-4 mt-4 mb-4 justify-center py-2 px-4
                          border border-transparent
                          text-sm leading-5 font-medium text-white bg-blue-600
                          hover:bg-blue-500 focus:outline-none focus:border-blue-700
                          focus:shadow-outline-blue active:bg-blue-700
                          transition duration-150 ease-in-out rounded">
                      Download
                    </div>
                  </router-link>
                </div>
                <div>
                  <button
                    @click="modalInfo.show = !modalInfo.show;modalInfo.content = item.img"
                    type="button"
                    class="ml-4 mt-4 mb-4 justify-center py-2 px-4
                          border border-transparent
                          text-sm leading-5 font-medium text-white bg-red-600
                          hover:bg-red-500 focus:outline-none focus:border-red-700
                          focus:shadow-outline-red active:bg-red-700
                          transition duration-150 ease-in-out rounded"
                  >
                    View
                  </button>
                </div>
              </div>
              <!-- <img src='../assets/arrowlink.svg' class="w-8 h-8 float-right" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="modalInfo.show">
      <div class="flex flex-col p-6 bg-white
          xl:w-286 lg:w-224 md:w-176 sm:w-144 w-112">
        <img :src='modalInfo.content' class="w-full" />
      </div>
    </Modal>
    <div class="w-full h-20 flex flex-col items-center b4footer-bg">
      &nbsp;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store/index';
import Modal from '@/components/Modal.vue';

export default Vue.extend({
  components: {
    Modal,
  },

  computed: {
    permissions: () => store.user.permissions,
  },

  methods: {
    authorised(service) {
      if (this.permissions.includes('all')) {
        return true;
      }
      if (service.permissions !== '') {
        return true;
      }
      return false;
    },
    canDownload(service) {
      if (this.permissions.includes('all')) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      downloadItems: Object.freeze([
        {
          title: 'MRC JOB CLASS CORRELATION GUIDE',
          desc: 'MRC JOB CLASS CORRELATION GUIDE',
          url: '/api/file/download_center/mrc_jes_correlation_guide.pdf',
          permissions: [''],
          img: '/api/file/download_center/mrc_jes_correlation_guide.png',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/jes.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
      ]),
      modalInfo: {
        show: false,
        content: '',
      },
    };
  },
});
</script>

<style>
.hero-bg {
  background: url('../assets/img/predictive1.jpg') center;
  background-size: cover;
  background-repeat: no-repeat;
}
.b4footer-bg {
  background: url('../assets/img/background/testimonial.jpg') top;
}
.multi-shadow {
  /* box-shadow: -10px 10px 0px rgba(33, 33, 33, 0.7), -20px 20px 0px rgba(33, 33, 33, 0.4), -30px 30px 0px rgba(33, 33, 33, 0.1); */
  box-shadow:
  5px 5px 0 0 rgba(36, 11, 54,0.3),
  10px 10px 0 0 rgba(241, 39, 17,0.2),
  15px 15px 0 0 rgba(195, 20, 50,0.1);
}
</style>
