<template>
  <div class="flex flex-col items-center">
    <!-- Hero section with background image, heading, subheading and button -->
    <div
      class="relative overflow-hidden bg-cover bg-no-repeat w-full hero-bg"
      style="
        height: 150px;
      ">
      <div
        class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
        style="background-color: rgba(255, 255, 255, 0.15)">
      </div>
    </div>
    <div class="w-full">
      <div class="flex h-full items-center justify-start m-auto max-w-7xl">
        <div class="relative -mt-16 px-2 py-4">
          <div
            class="w-96 block rounded bg-white p-6 dark:bg-neutral-700 multi-shadow">
            <h1 class="font-semibold text-2xl p-4">Online Services</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero section with background image, heading, subheading and button -->
    <div class="w-full flex flex-col max-w-7xl py-10">
      <div class="grid grid-cols-3 items-stretch">
        <router-link
          class='block px-4 py-1 rounded-lg hover:border hover:border-pink-200 hover:border-b-0 hover:shadow-lg border mx-2 my-5'
          v-for='item in onlineServices'
          :key="item.title"
          :to='item.url'
          :target="item.newWindow?'_blank':'_self'"
          :class="authorised(item)?
              '':
              'cursor-not-allowed pointer-events-none border-gray-100 bg-gray-100'"
        >
          <div class='flex flex-col items-center justify-center h-full text-center sm:flex-row sm:justify-start sm:text-left'>
            <img
              class='flex-shrink-0 object-cover object-center w-12 h-12 mb-4 rounded-lg sm:mb-0'
              :class="authorised(item)? '': 'bg-gray-500'"
              :src='item.icon'
              :alt='item.title'
            />
            <div class='flex-grow w-10/12 sm:pl-8'>
              <h2 class='text-lg font-medium text-gray-900 title-font'>
                {{ item.title }}
              </h2>
              <img src='../assets/arrowlink.svg' class="w-8 h-8 float-right" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="w-full h-20 flex flex-col items-center b4footer-bg">
      &nbsp;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store/index';

export default Vue.extend({
  computed: {
    permissions: () => store.user.permissions,
  },

  methods: {
    authorised(service) {
      if (this.permissions.includes('all')) {
        return true;
      }
      if (service.permissions.some((permission) => this.permissions.includes(permission))
      ) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      onlineServices: Object.freeze([
        {
          title: 'Executive Compensation Public',
          desc: 'Board of Directors’ Pay for listed companies from annual reports.',
          descurl: '/tools/executive_compensation_public',
          url: '/exec-comp/',
          permissions: ['exec_comp_public'],
          img: '/img/online_services/1.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/ec_public2.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Executive Compensation Private',
          desc: 'Yearly survey data from private and public-listed companies.',
          descurl: '/tools/executive_compensation_private',
          url: '/exec-comp-private/',
          permissions: ['exec_comp_private'],
          img: '/img/online_services/2.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/ec_private2.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Super Compensation',
          desc: 'Salary data powered by MRC machine learning algorithm.',
          descurl: '/tools/super_compensation',
          url: '/super-comp/',
          permissions: ['super_comp'],
          img: '/img/online_services/3.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/supercomp.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Employee Benefits',
          desc: 'View benefits policies in the market across APAC.',
          descurl: '/tools/benefits_data_services',
          url: '/employee-benefits/',
          permissions: ['employee_benefits'],
          img: '/img/online_services/4.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/benefit2.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'APAC Market Insights',
          desc: 'Bi-annual survey, provides guides on APAC economics (GDP, inflation, unemployment, FDI, PMI) & HR guide (pay increase, attrition, workforce demand, bonus, fresh grad start rates).',
          descurl: '/market_insights',
          url: '/mi/',
          permissions: ['mi_survey'],
          img: '/img/online_services/4.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/mi_survey.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Sales Incentive Practices',
          desc: 'Provides an update of the latest sales commission/ bonus/ incentive trends covering 15 APAC locations, and provides insights into country-specific practices.',
          descurl: '/tools/sale_incentive_report',
          url: '/sip/',
          permissions: ['sip'],
          img: '/img/online_services/4.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/sip.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Job Evaluation System',
          desc: 'Evaluate jobs and benchmark it againts the market.',
          descurl: '/home/compensation_benchmarking',
          url: '/jes/',
          permissions: ['jes'],
          img: '/img/online_services/5.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/jes.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'AI Market Sensor for HR',
          desc: 'Pay Trends, Forecast Predictor, Employment Legislations, Relocation Calculator, Productivity & Financials, HR Market, and Economy.',
          descurl: '/tools/pay_trends',
          url: '/aims/',
          permissions: [
            'aims.nonrewards',
            'mds',
            'forecast_calculator',
            'hr_markets',
            'pay_trends',
            'relocation',
            'legislations_news',
            'finance_productivity',
            'economy',
            'personnel-expense',
          ],
          img: '/img/online_services/6.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/aims.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Employee Risk Predictor',
          desc: 'Predict individual resignations with HR adjustable dashboards.',
          descurl: '/ai/employee_risk_predictor',
          url: '/erp/',
          permissions: ['erp'],
          img: '/img/online_services/7.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/risk.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'AI HR Writer',
          desc: 'Write job description, recruitment ads post and hr related letter includes call-for-interview, onboarding, promotion, salary increase by AI powered tools.',
          descurl: '/ai/ai_content_generator',
          url: '/content-guide/',
          permissions: ['content_guide'],
          img: '/img/online_services/7.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/ai_hr_writer.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Performance Appraisal System',
          desc: 'Appraise employee performance, and determine and allocate salary increases and performance bonuses',
          descurl: '',
          url: '/pas/',
          permissions: ['pas_appraiser'],
          img: '/img/online_services/7.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/pas.svg'),
          color: 'bg-gray-200',
          newWindow: true,
        },
        {
          title: 'Monthly Market Insights',
          desc: 'Download monthly C&B market insights.',
          descurl: '/market_insights',
          url: '/market_insights_members/',
          permissions: ['contrarian_view', 'econ_guide', 'legis_guide'],
          img: '/img/online_services/8.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/marketinsight.svg'),
          color: 'bg-gray-200',
          newWindow: false,
        },
        {
          title: 'Seminars / Symposium / Retail Forum Materials / Public Videos',
          desc: 'Download seminar materials and re-watch the seminar video.',
          descurl: '/seminars',
          url: '/seminar_members/',
          permissions: ['seminar_material'],
          img: '/img/online_services/9.jpg',
          // eslint-disable-next-line global-require
          icon: require('@/assets/img/online/seminar.svg'),
          color: 'bg-gray-200',
          newWindow: false,
        },
      ]),
    };
  },
});
</script>

<style>
.hero-bg {
  background: url('../assets/img/predictive1.jpg') center;
  background-size: cover;
  background-repeat: no-repeat;
}
.b4footer-bg {
  background: url('../assets/img/background/testimonial.jpg') top;
}
.multi-shadow {
  /* box-shadow: -10px 10px 0px rgba(33, 33, 33, 0.7), -20px 20px 0px rgba(33, 33, 33, 0.4), -30px 30px 0px rgba(33, 33, 33, 0.1); */
  box-shadow:
  5px 5px 0 0 rgba(36, 11, 54,0.3),
  10px 10px 0 0 rgba(241, 39, 17,0.2),
  15px 15px 0 0 rgba(195, 20, 50,0.1);
}
</style>
